
label {
  font-weight: bold;
}

label[required]::after {
  content: '*';
  color: var(--orange-hover);
}

input {
  border-width: 0 0 1px 0;
  border-color: #ddd;
  background: none;
  outline: none;
  width: 90%;
  caret-color: var(--evo-caret-color);
  &:read-only {
    border: 0;
  }
}

page-patient {
  input {
    border: 0;
  }
}

input[type="checkbox"] + label.checkmark {
  display: inline-block;
  background: url(assets/evo-icons/icon_checkbox.svg) no-repeat;
  width: 100%;
  height: 1.5em;
  background-size: contain;
}

input[type="checkbox"]:checked + label.checkmark {
  background: url(assets/evo-icons/icon_checkbox_ok.svg) no-repeat;
  background-size: contain;
}
input[type="time"] {
  background: url(assets/evo-icons-dark/icon_clock.svg) no-repeat 100% 50%/ .8rem;
}
input[type="time"]::-webkit-calendar-picker-indicator {
  background: none;
}
