/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */
 @use "sass:meta";
 /* Core CSS required for Ionic components to work properly */
 @import "~@ionic/angular/css/core.css";

 /* Basic CSS for apps built with Ionic */
 @import "~@ionic/angular/css/normalize.css";
 @import "~@ionic/angular/css/structure.css";
 @import "~@ionic/angular/css/typography.css";
 @import '~@ionic/angular/css/display.css';

 /* Optional CSS utils that can be commented out */
 @import "~@ionic/angular/css/padding.css";
 @import "~@ionic/angular/css/float-elements.css";
 @import "~@ionic/angular/css/text-alignment.css";
 @import "~@ionic/angular/css/text-transformation.css";
 @import "~@ionic/angular/css/flex-utils.css";

 /* Import from project */
 @import "icons.scss";
 @import "form.scss";

 @font-face {
   font-family: "PTSansv12";
   src: local("PTSansv12"), url(assets/fonts/pt-sans-v12-latin-regular.ttf) format("truetype");
 }
 :root {
   --ion-text-color: var(--default-text-color);
   --ion-text-color-rgb: var(--default-text-color-rgb);
   --ion-background-color: var(--default-background-color);
   --ion-background-color-rgb: var(--default-background-color-rgb);
   --ion-backdrop-opacity: var(--default-backdrop-opacity);
   --ion-font-family: var(--default-font-family);
   --ion-item-background: var(--default-background-color);

   --ion-color-primary: #BFBFBF;
   --ion-color-primary-rgb: 191,191,191;
   --ion-color-primary-contrast: #FFFFFF;
   --ion-color-primary-contrast-rgb: 255,255,255;
   --ion-color-primary-shade: #a8a8a8;
   --ion-color-primary-tint: #c5c5c5;

   --ion-color-secondary: #F8BF96;
   --ion-color-secondary-rgb: 248,191,150;
   --ion-color-secondary-contrast: #FFFFFF;
   --ion-color-secondary-contrast-rgb: 255,255,255;
   --ion-color-secondary-shade: #daa884;
   --ion-color-secondary-tint: #f9c5a1;

   --ion-color-tertiary: #F5822F;
   --ion-color-tertiary-rgb: 245,130,47;
   --ion-color-tertiary-contrast: #FFFFFF;
   --ion-color-tertiary-contrast-rgb: 255,255,255;
   --ion-color-tertiary-shade: #d87229;
   --ion-color-tertiary-tint: #f68f44;
   --ion-toolbar-color: #ed6d18;
 }

 :root {
   font-size: min(max(16px, min(4vw, 4vh)), 24px);
 }

 body {
   color: white;
 }

 ion-content {
   color: var(--default-text-color);
   --background: var(--default-background-color);
   font-weight: 500;
 }

 textarea {
  font-size: 0.7rem;
  background-color: var(--secondary-background);
  color: var(--default-text-color);
  border: 3px solid var(--evo-border-color);;
  border-radius: 20px;
  width: 100%;
  height: 100%;
  box-shadow: -2px 2px 5px #00000029;
  outline: none;
  resize: none;
  padding: 0.5em 1em 0.5em 1em;
  &::placeholder {
    color: var(--placeholder-color);
  }
  &:focus {
    border-color: var(--orange-hover);
  }
 }

 .icon-inner {
   height: 1rem;
   width: auto;
 }

 .alertHeader, .alert-button {
   color: var(--default-text-color) !important;
 }
 h2.alertHeader {
   color: var(--default-text-color) !important;
 }

 .outline-button {
   background: transparent !important;
   color: #ffa84c !important;
   border: 0 !important;
   box-shadow: white 0 0 0 !important;
   ion-icon {
     color: #ffa84c !important;
   }
 }
 .tp-button {
   padding: 12px 20px;
   border-radius: 20px;
   display: inline-block;
   font-size: .7rem;
   color: #fff;
   //color: var(--default-text-color);
   background-color: var(--orange);
   margin-right: 14px;
 }
 .tabbar {
   color: grey !important;
   background-color: #ffffff !important;
 }

 .tabbar a[aria-selected="true"] {
   ion-icon {
     color: #ed6d18 !important;
   }
   .tab-button-text {
     color: #ed6d18 !important;
   }
 }

 h1 {
   font-weight: 700 !important;
 }

 h2.alert-title {
   color: rgb(102, 102, 102);
 }

 .card-md {
   text-overflow: ellipsis;
 }

 .toast-notification {
   div.toast-wrapper {
     max-width: 800px;
     div.toast-container {
       height: 6rem;
       div.toast-message {
         font-size: 2rem;
       }
     }
   }
 }

 /*
 .back-button {
   //mask-image: url(.assets/evo-icon/evo_arrow_left.svg);
   background-image: url(.assets/evo-icons/evo_arrow_left.svg);
   width: 1.6em;
     height: 1.6em;
     color: #EC7303;
 }
 */

.scrollbar-height-full .ng-scroll-content {
  height: 100% !important;
}


 // === IONIC MODAL ===
 .modal-wrapper {
   --border-radius: 10px;
   --box-shadow: -2px 2px 5px #00000029;
   height: 80% !important;
   width: 50% !important;
   max-height: initial !important;
   min-height: initial !important;
   position: absolute !important;
   display: block !important;

   ion-content{
     position: relative;
     bottom: initial;
   }
 }

 .confirmation-modal {
   --height: 45% !important;
   --width: 40% !important;
 }

 .info-modal {
   --height: 70% !important;
   --width: 40% !important;
 }

 // Fix for using keyboard in modal
 ion-modal {
   ion-backdrop {
     visibility: visible !important;
   }
 }

 // === SPLASH ===
 .splash {
   position: absolute;
   width: 100%;
   height: 100%;
   z-index: 999;
   display: flex;
   align-items: center;
   justify-content: center;
   background: rgb(255, 123, 0);
 }

 .sk-cube-grid {
   width: 40px;
   height: 40px;
   margin: 100px auto;
 }

 .sk-cube-grid .sk-cube {
   width: 33%;
   height: 33%;
   background-color: rgb(255, 255, 255);
   float: left;
   -webkit-animation: sk-cubeGridScaleDelay 1.3s infinite ease-in-out;
   animation: sk-cubeGridScaleDelay 1.3s infinite ease-in-out;
 }

 .sk-cube-grid .sk-cube1 {
   -webkit-animation-delay: 0.2s;
   animation-delay: 0.2s;
 }

 .sk-cube-grid .sk-cube2 {
   -webkit-animation-delay: 0.3s;
   animation-delay: 0.3s;
 }

 .sk-cube-grid .sk-cube3 {
   -webkit-animation-delay: 0.4s;
   animation-delay: 0.4s;
 }

 .sk-cube-grid .sk-cube4 {
   -webkit-animation-delay: 0.1s;
   animation-delay: 0.1s;
 }

 .sk-cube-grid .sk-cube5 {
   -webkit-animation-delay: 0.2s;
   animation-delay: 0.2s;
 }

 .sk-cube-grid .sk-cube6 {
   -webkit-animation-delay: 0.3s;
   animation-delay: 0.3s;
 }

 .sk-cube-grid .sk-cube7 {
   -webkit-animation-delay: 0s;
   animation-delay: 0s;
 }

 .sk-cube-grid .sk-cube8 {
   -webkit-animation-delay: 0.1s;
   animation-delay: 0.1s;
 }

 .sk-cube-grid .sk-cube9 {
   -webkit-animation-delay: 0.2s;
   animation-delay: 0.2s;
 }

 @-webkit-keyframes sk-cubeGridScaleDelay {
   0%,
   70%,
   100% {
     -webkit-transform: scale3D(1, 1, 1);
     transform: scale3D(1, 1, 1);
   }

   35% {
     -webkit-transform: scale3D(0, 0, 1);
     transform: scale3D(0, 0, 1);
   }
 }

 @keyframes sk-cubeGridScaleDelay {
   0%,
   70%,
   100% {
     -webkit-transform: scale3D(1, 1, 1);
     transform: scale3D(1, 1, 1);
   }

   35% {
     -webkit-transform: scale3D(0, 0, 1);
     transform: scale3D(0, 0, 1);
   }
 }

 ion-item::part(native) {
   border-color: #ff7b00 !important;
 }

 .native-input.sc-ion-input-md {
   height: 30px;
   padding-bottom: 0.3rem;
 }

 ion-button,
 ion-button:hover {
   --box-shadow: none;
 }

 #logo_right {
   position: fixed !important;
   top: 1px !important;
   right: 1%;
   z-index: 1000 !important;
 }

 .searchbar {
    width: 95%;
    position: relative;
    background: var(--secondary-background) !important;
    border: 3px solid var(--evo-border-color);;
    border-radius: 10px;
    box-shadow: -2px 2px 5px #00000029;
    margin-top: 15px;
    font-size: .7rem;
    padding: 7px;
    &:focus {
      border-color: var(--orange-hover);
    }
    .icon {
      position: absolute;
      background-image: var(--evo-search);
      background-size: contain;
      width: 3rem;
      height: 3rem;
      transform: translateX(-50%);
      left: 100%;
      &:hover {
        background-image: var(--evo-search-hover);
      }
    }
  }

 ion-tab-bar {
   height: 4rem;
   box-shadow: 0 0 1px 0 rgba(0,0,0,0.56);
 }

 ion-tab-button {
   max-width: none !important;
 }

 ion-modal {
   ion-toolbar {
     --padding-top: 0.8em;
     --padding-bottom: 0.6em;
     --padding-start: 0.6em;
     --padding-end: 0.6em;
     --background: var(--secondary-background);
     ion-title {
       font-size: 1rem;
       letter-spacing: 1px;
       font-weight: bold;
       width: fit-content;
       max-width: 100%;
       color: var(--headline-color);
       padding-left: 10px;
       &::after {
         content: '.';
         color: #ed6d18;
       }
     }
   }
   ion-header::after {
     all:unset !important;
   }
 }

 input[type="time"]::-webkit-calendar-picker-indicator {
  background: url(assets/evo-icons-dark/icon_clock.svg);
  background-size: contain;
  height: 0.8em;
  width: 0.8em;
  font-size: 1em;
 }

 mat-datepicker-toggle {
   .mat-ripple, .mat-button-focus-overlay {
    display: none;
   }
   .mat-icon-button {
    height: 1em;
    width: 1em;
    font-size: 1em;
  }
 }
.mat-datepicker-content {
  background-color: var(--secondary-background);

  .mat-calendar-table {
    .mat-calendar-body {
      tr>td.mat-calendar-body-label{
        color: transparent;
        padding: 0 !important;
      }
      .mat-calendar-body-cell.mat-calendar-body-disabled>.mat-calendar-body-cell-content {
        color: #bfbfbf;
      }
      .mat-calendar-body-cell>.mat-calendar-body-cell-content {
        color: var(--default-text-color);
        background: transparent;
        border-radius: 5px;
        &.mat-calendar-body-selected {
          box-shadow: none;
          border: 2px solid #bfbfbf;
        }
      }
      .mat-calendar-body-cell.holiday>.mat-calendar-body-cell-content {
        color: var(--orange-hover);
      }

      .mat-calendar-body-cell:not(.mat-calendar-body-disabled):hover
        > .mat-calendar-body-cell-content {
        background-color: transparent !important;
        border-color: var(--calendar--border);
      }
    }
    .mat-calendar-table-header {
      color: var(--default-text-color);
      th {
        font-size: 0.5rem;
        font-weight: bold;
        text-transform: uppercase;
        font-family: "PTSANSV12";
      }
    }
  }
}

 ion-select .select-placeholder {
   color: var(--default-text-color) !important;
 }

 ion-tabs ion-tab-button ion-label {
   font-size: 1vw;
   font-weight: bold;
   letter-spacing: normal;
   margin: 0;
 }

 ion-tab-button {
   ion-label {
     transition: all 0.3s ease-in-out;
   }

 }

 ion-tab-button[aria-selected=true] {
   border-color: var(--orange-hover);

   ion-label {
     color: var(--orange-hover);
     transition: all 0.3s ease-in-out;
   }
   ion-icon {
     color: var(--orange-hover);
   }

   &:hover {
     border-color: var(--orange-hover);
     ion-label, ion-icon {
       color: var(--orange-hover);
     }
   }
 }

 #add-button::part(native) {
   background: transparent !important;
   padding-inline-start: 35px;
   padding-inline-end: 35px
 }


 // Helper
 .c-orange {
   color: #ed6d18 !important;
 }

 ion-tabs {
   display: grid !important;
   grid-template-areas: "content mainnav";
   grid-template-columns: 1fr 10vw;

   ion-tab-bar {
     grid-area: mainnav;
     background: var(--quaternary-background);
     position: absolute;
     top: 0;
     z-index: 10000;
     width: 100%;
     height: 100vh;
     display: block;
     padding-left: 0;
     ion-tab-button {
       font-size: 1rem !important;
       color: #fff;
       position: relative;
       background: var(--quaternary-background);
       display: block;
       height: 22vh;
       border-top: 0;
       border-left: 3px solid transparent;
       --padding-end: .2em;
       --padding-start: .2em;

       &:hover {
         ion-label, ion-icon {
           color: #fff;
         }
       }

       span {
         color: #F47920;
       }

       ion-label {
         font-weight: bold;
       }

       ion-icon {
         height: 20vh;
       }
     }
   }
   .ion-page {
     grid-area: content;
   }
 }

 .scrollbar-pages {
   height: calc(100% - 4rem) !important;
   [class~="ps__rail-y"] {
     margin-right: 0.5rem;
   }
 }

 .scrollbar {
   --scrollbar-border-radius: 10px !important;
   --scrollbar-track-color: var(--evo-scrollbar-track-color) !important;
   --scrollbar-thumb-color: var(--evo-border-color) !important;
   --scrollbar-size: 6px !important;
   --scrollbar-padding: 0;
   --scrollbar-viewport-margin: 50px;
   &.scrollbar-modal {
     --scrollbar-padding: 0px !important;

     .ng-scroll-viewport {
       border: 2rem solid #ffffff;
       border-bottom-width: 4rem;
     }
   }

   &.scrollbar-height-header {
     height: calc(100% - 4rem) !important;
   }
 }

 .header {
   height: 60px;
   position: relative;
   width: 100%;
   margin: 0 auto;
   background: url("assets/evo-icons/evocare_grey.svg") 90% 20px /auto 40px no-repeat var(--header-background);
   --ion-toolbar-background: transparent;
   img {
     margin: 10px;
   }
   &__logo svg {
     margin: .5rem 0 0 .5rem;
     width: auto;
     height: 60px;
   }
 }

 ion-buttons {
   gap: 1em;
   color: var(--default-text-color);
   cursor: pointer;
 }

 h1.page-title {
   color: var(--default-title-color);
   font-size: 1.6rem;
   letter-spacing: 2px;
   margin-left: 10px;
   display: inline-block;
   font-weight: bold !important;
   &--patient-detail {
     margin-left: 9vw;
   }
   span {
     color: #ed6d18;
   }
 }

 .shadow-box {
   box-shadow: -2px 2px 15px -5px rgba(0,0,0,0.51);
 }

 .evo-button {
   box-shadow: -2px 2px 5px #00000029;
   border-radius: 1vw;
   background: var(--orange);
   font-size: 1vw;
   color: #fff;
   align-items: center;
   justify-content: center;
   font-weight: bold;
   display: flex;
   width: 100%;
   cursor: pointer;
   padding: 0.8vw 0;

   &.modal-button {
     font-size: 0.7rem;
     padding: 0.5vw 0;
   }

   &:disabled {
     opacity: 0.7;
   }
   &:not(:disabled):hover {
     background: var(--orange-hover);
   }
 }

 .no-ripple {
   --ripple-color: transparent;
 }

 .table-headers {
   margin: 20px;

   .headerfont {
     line-height: 1.5rem;
     border-radius: 1vw;
     background: var(--tertiary-background);
     text-align: center;
     margin: 0;
     color: #fff;
     width: 100%;
     display: block !important;
     box-shadow: -2px 2px 5px #0000004D;
     font-weight: bold;
     white-space: nowrap;

     p {
       display: inline;
     }

     ion-icon {
       height: 20px;
       position: relative;
       top: 5px;
       transition: all 200ms ease-in-out;
       &:hover {
         transform: scale(1.1);
       }
     }

     &.active {
       cursor: pointer;
     }
   }
 }

 .add-button {
   font-size: 1.5rem;
   position: absolute !important;
   box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.26);
   border-radius: 50% !important;
   height: 55px !important;
   width: 55px;
   bottom: 20px !important;
   padding-right: -40px;
   right: 30px;
   z-index: 99;
   background: #fff;
   cursor: pointer;
   border: 1px solid #fff;

   ion-icon {
     color: #fff;
     position: absolute;
     top: 0;
     left: 0;
     width: 55px;
     height: 55px;
     background: var(--orange-hover);
   }

   &:not(.disabled):hover {
     background: var(--orange-hover);
     border-color: var(--orange-hover);

     ion-icon {
       background: #fff;
     }
   }

   &.disabled {
     cursor: default;
     opacity: .3;
   }
 }

 button.goback {
   width: 7vw;
   background: var(--orange);
   color: #fff;
   padding: 0.5vw;
   border-radius: 1vw;
   margin-left: 20px;

   &--patient-detail {
     margin-top: 20px;
     position: fixed;
     z-index: 20;
   }

   ion-icon {
     transform: rotate(180deg);
     font-size: 1vw !important;
   }

   &:hover {
     background: var(--orange-hover);
   }
 }

 .patient-form {
   width: 100%;


   mat-datepicker-toggle {
     font-size: 1.3rem;
     top: -.1rem;
   }

   .evo-button {
     margin: 30px auto;
   }

   ion-item {
     background: var(--secondary-background);
     --background: var(--secondary-background);
     box-shadow: 2px 2px 5px #00000029;
     border-radius: 20px;
     aspect-ratio: 95 / 7;
     display: flex;
     color: var(--default-text-color);

     ion-label {
       color: #fff !important;
       display: inline-block !important;
       background: var(--quaternary-background);
       width: 40%;
       height: 75px;
       line-height: 75px;
       font-size: 0.6rem;
       font-weight: bold;
       vertical-align: top;
       margin: 0;
       max-width: 45% !important;
       text-align: center;
       flex: 1 !important;
       --padding-start: 0;
     }

     .input-icon {
       margin-bottom: 20px;
       color: var(--default-text-color);
       flex: 1;

       &.custom-size {
         font-size: 1vw !important;
         display: inline-block;
         vertical-align: middle;
         margin-bottom: 0;
       }
     }
   }
 }

 .desc-box {
   font-size: 0.7rem !important;
   color: var(--default-text-color) !important;
   border: 0.3vw solid var(--evo-border-color) !important;
   box-shadow: 2px 2px 5px #00000029;
   border-radius: 20px !important;
   padding: 2% 0 !important;
   margin-bottom: 30px !important;
   height: auto !important;
   display: block !important;
   aspect-ratio: unset !important;
   background: var(--secondary-background);
 }

 event-calendar .mat-calendar {
   width: 100% !important;
   box-shadow: -2px 2px 15px -5px rgba(0,0,0,0.51);
   border-radius: 20px;
   font-family: PTSansv12;
   margin-top: 16px;
   color: var(--default-text-color) !important;
   background: var(--secondary-background);

   .mat-calendar-header {
     background: #BFBFBF;
     border-radius: 20px 20px 0 0;
     padding: 0;
   }

   .mat-calendar-table-header-divider {
     display: none;
   }

   .mat-calendar-controls {
     position: relative;
     display: block;
     margin: 0;
     height: 73px;

     .mat-calendar-spacer,
     .mat-calendar-period-button {
       display: none !important;
     }

     .mat-calendar-next-button {
       position: absolute;
       right: 0;
     }
   }

   .mat-calendar-table {
     width: 70% !important;
     margin: 0 auto;

     .mat-calendar-body-label:first-child {
       height: 0;
       overflow: hidden;
       padding: 0 !important;
       text-indent: 100%;
       white-space: nowrap;
     }

     .mat-calendar-body-cell>.mat-calendar-body-cell-content {
        font-size: 1.2vw;
        height: 70%;
        background: transparent;
        border-width: 1px;
        border-style: solid;
        border-radius: 5px;
        color: var(--default-text-color);
     }

     .mat-calendar-body-cell:not(.mat-calendar-body-disabled):hover
        > .mat-calendar-body-cell-content {
          background: transparent;
          border-color: var(--calendar--border);
      }

     .mat-calendar-body-cell {
       padding-top: 1vw !important;
       padding-bottom: 1vw !important;

       .mat-calendar-body-cell::before,
       .mat-calendar-body-cell::after,
       .mat-calendar-body-cell-preview {
         height: 70%;
       }

       .mat-calendar-body-today {
         background: #F8BF96 !important;
       }

       .mat-calendar-body-selected {
         box-shadow: none;
         border-color: #000;
       }

       &.holiday {
         .mat-calendar-body-cell-content {
           color: var(--orange-hover);
         }
       }

       &.event {
         .mat-calendar-body-cell-content {
           color: var(--default-text-color);
           background: var(--calendar-event) !important;
         }
       }
     }

     .mat-calendar-table-header {
       td, th {
         color: var(--default-text-color);
         width: 70px !important;
         margin-bottom: 10px;
         font-weight: bold;
         font-size: 1.5vw !important;

         &:last-child {
           //color: var(--orange-hover);
         }
       }
     }
   }
 }

 .messageElement {

   .item {
     font-size: 1rem;
     background: #BFBFBF;
     border-radius: 10px;
     padding: 20px;
     margin: 0 50px 22px;
     position: relative;

     .message-icon {
       position: absolute;
       bottom: -10px;
       left: -50px;
       z-index: 100;
       background: url(assets/evo-icons/chat_2.svg) no-repeat left bottom;
       width: 80px;
       height: 35px;
     }

     &.Sender {
       background-color: var(--orange);
       .message-icon {
         left: auto;
         right: -50px;
         background: var(--evo-chat-response) no-repeat right bottom;
       }
     }
   }
 }

.modal-default {
  ion-content {
    --background: var(--secondary-background);
  }
  --ion-item-background: var(--secondary-background);
  --border-radius: 20px;
}
::ng-deep .mat-calendar-body-cell:not(.mat-calendar-body-disabled):hover > .mat-calendar-body-cell-content {
  border-color: var(--calendar--border);
  background-color: transparent !important;
}
