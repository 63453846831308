@keyframes pulse-animation {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 100;
  }
  100% {
    opacity: 0;
  }
}
// === CUSTOM ICONS ===
ion-icon {
    font-size: 1.1rem !important;

    &[class*="custom-"] {
      mask-size: contain;
      mask-position: 50% 50%;
      mask-repeat: no-repeat;
      background: currentColor;
      width: 1rem;
      height: 1rem;
    }

    &[class*="custom-audio"] {
      mask-image: url(assets/evo-icons/evo_audio.svg);
    }

    &[class*="custom-balls"] {
      mask-image: url(assets/evo-icons/evo_balls.svg);
    }

    &[class*="custom-cancel"] {
      mask-image: url(assets/evo-icons/evo_cancel.svg);
    }

    &[class*="custom-check"] {
      mask-image: url(assets/evo-icons/evo_check.svg);
    }
    &[class*="custom-calendar"] {
      mask-image: url(assets/evo-icons/evo_calendar.svg);
    }
    &[class*="custom-diagramm"] {
      mask-image: url(assets/evo-icons/evo_diagramm.svg);
    }
    &[class*="custom-door"] {
      mask-image: url(assets/evo-icons/evo_door_floor.svg);
    }
    &[class*="custom-door-tab"] {
      mask-image: url(assets/evo-icons/evo_door.svg);
      width: 2em;
      height: 2em;
    }
    &[class*="custom-happy"] {
      mask-image: url(assets/evo-icons/evo_happy.svg);
      width: 2em;
      height: 2em;
    }
    &[class*="custom-happy-tab"] {
      mask-image: url(assets/evo-icons/evo_happy.svg);
      width: 2em;
      height: 2em;
    }
    &[class*="custom-info"] {
      mask-image: url(assets/evo-icons/evo_info.svg);
    }
    &[class*="custom-letter"] {
      mask-image: url(assets/evo-icons/evo_letter.svg);
      animation: pulse-animation 1s infinite;
    }
    &[class*="custom-lock-dark"] {
      margin-top: .2rem;
      mask-image: url(assets/evo-icons-dark/evo_lock.svg);
      width: 1.3rem;
      height: 1.3rem;
    }
    &[class*="custom-lock-light"] {
      margin-top: .2rem;
      mask-image: url(assets/evo-icons/evo_lock.svg);
      width: 1.3rem;
      height: 1.3rem;
    }
    &[class*="custom-microphone-outline"] {
      mask-image: url(assets/evo-icons/evo_microphone_outline.svg);
    }
    &[class*="custom-microphone"] {
      mask-image: url(assets/evo-icons/evo_microphone.svg);
    }
    &[class*="custom-noticed"] {
      mask-image: url(assets/evo-icons/evo_noticed.svg);
    }
    &[class*="custom-patients"] {
      mask-image: url(assets/evo-icons/evo_patients.svg);
      width: 2em;
      height: 2em;
    }
    &[class*="custom-phone"] {
      mask-image: url(assets/evo-icons/evo_phone.svg);
    }
    &[class*="custom-phone-tab"] {
      mask-image: url(assets/evo-icons/evo_phone.svg);
      width: 2em;
      height: 2em;
    }
    &[class*="custom-play"] {
      mask-image: url(assets/evo-icons/evo_play.svg);
    }

    &[class*="custom-plus"] {
      mask-image: url(assets/evo-icons/evo_plus.svg);
    }

    &[class*="custom-print"] {
      mask-image: url(assets/evo-icons/evo_print.svg);
    }

    &[class*="custom-record"] {
      mask-image: url(assets/evo-icons/evo_record.svg);
    }

    &[class*="custom-reload"] {
      mask-image: url(assets/evo-icons/evo_reload.svg);
      width: 1.8em;
      height: 1.8em;
    }
    &[class*="custom-send"] {
      mask-image: url(assets/evo-icons/evo_send.svg);
    }
    &[class*="custom-arrow-left"] {
      mask-image: url(assets/evo-icons/evo_arrow_left.svg);
    }
    &[class*="custom-config-fat"] {
      mask-image: url(assets/evo-icons/evo_config_fat.svg);
      width: 2em;
      height: 2em;
    }
    &[class*="custom-search"] {
      mask-image: url(assets/evo-icons/evo_search.svg);
      width: 2em;
      height: 2em;
    }
    &[class*="custom-overview"] {
      mask-image: url(assets/evo-icons/icon_menu_overview.svg);
      width: 2em;
      height: 2em;
    }
    &[class*="custom-catalogue"] {
      mask-image: url(assets/evo-icons/icon_menu_catalogue.svg);
      width: 2em;
      height: 2em;
    }
    &[class*="custom-grouptherapy"] {
      mask-image: url(assets/evo-icons/icon_menu_grouptherapy.svg);
      width: 2em;
      height: 2em;
    }
    &[class*="custom-settings"] {
      mask-image: url(assets/evo-icons/icon_menu_settings.svg);
      width: 2em;
      height: 2em;
    }
    &[class*="custom-home"] {
      mask-image: url(assets/evo-icons/icon_home.svg);
      width: 2em;
      height: 2em;
    }
    &[class*="custom-not-home"] {
      mask-image: url(assets/evo-icons/icon_nothome.svg);
      width: 2em;
      height: 2em;
    }
    &[class*="custom-status-ok"] {
      mask-image: url(assets/evo-icons/icon_status_ok.svg);
      width: 2em;
      height: 2em;
    }
    &[class*="custom-status-todo"] {
      mask-image: url(assets/evo-icons/icon_status_todo.svg);
      width: 2em;
      height: 2em;
      animation: pulse-animation 1s infinite;
    }
    &[class*="custom-message"] {
      mask-image: url(assets/evo-icons/icon_messages2_light.svg);
      width: 2em;
      height: 2em;
    }
    &[class*="custom-entry"] {
      mask-image: url(assets/evo-icons/icon_entry.svg);
      width: 2em;
      height: 2em;
    }
    &[class*="custom-message-video"] {
      mask-image: url(assets/evo-icons/icon_videocall.svg);
      width: 2em;
      height: 2em;
    }
    &[class*="custom-message-text"] {
      mask-image: url(assets/evo-icons/icon_messages.svg);
      width: 2em;
      height: 2em;
      animation: pulse-animation 1s infinite;
    }
    &[class*="custom-edit"] {
      mask-image: url(assets/evo-icons/icon_edit.svg);
      width: 2em;
      height: 2em;
    }
    &[class*="custom-calendar-new"] {
      mask-image: url(assets/evo-icons/icon_calendar.svg);
      width: 2em;
      height: 2em;
    }
    &[class*="custom-graph"] {
      mask-image: url(assets/evo-icons/icon_graph.svg);
      width: 2em;
      height: 2em;
    }
    &[class*="custom-add"] {
      mask-image: url(assets/evo-icons/icon_add.svg);
      width: 2em;
      height: 2em;
    }
    &[class*="custom-edit2"] {
      mask-image: url(assets/evo-icons/icon_edit2.svg);
      width: 2em;
      height: 2em;
    }
    &[class*="custom-delete"] {
      mask-image: url(assets/evo-icons/icon_delete.svg);
      width: 2em;
      height: 2em;
    }
    &[class*="custom-back"] {
      mask-image: url(assets/evo-icons/icon_arrow.svg);
      width: 2em;
      height: 2em;
    }
    &[class*="custom-phone2"] {
      mask-image: url(assets/evo-icons/icon_phone.svg);
      width: 2em;
      height: 2em;
    }
    &[class*="custom-email"] {
      mask-image: url(assets/evo-icons/icon_email.svg);
      width: 1.5rem;
      height: 1.5rem;
    }
    &[class*="custom-smiley"] {
      mask-image: url(assets/evo-icons/icon_smiley.svg);
      width: 1.5rem;
      height: 1.5rem;
    }

    &[class*="modal"] {
      background-color: #BFBFBF;
      width: 1.5rem;
      height: 1.5rem;
      &:hover {
        background-color: var(--orange-hover);
      }
    }

    &[class*="custom-close"] {
      mask-image: url(assets/evo-icons/icon_close.svg);
    }

    &[class*="custom-ok"] {
      mask-image: url(assets/evo-icons/icon_ok.svg);
    }

    &[class*="custom-alert"] {
      mask-image: url(assets/evo-icons/icon_alert.svg);
      width: 2em;
      height: 2em;
    }
    &[class*="custom-extend"] {
      mask-image: url(assets/evo-icons/icon_extend.svg);
      width: 2em;
      height: 2em;
    }
    &[class*="custom-mike"] {
      mask-image: url(assets/evo-icons/icon_mike.svg);
      width: 2em;
      height: 2em;
    }
  &[class*="custom-mobile"] {
    mask-image: url(assets/evo-icons/icon_mobile.svg);
    width: 2em;
    height: 2em;
  }
  }
